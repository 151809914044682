import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/service/authentication.service';
import { APIRoutes } from '../enums/api-routes.enum';
@Injectable({
  providedIn: 'root',
})
export class APIService {
  public errorMessage: BehaviorSubject<any> = new BehaviorSubject([]);
  public _config = environment;
  public serverURL = this._config.serverIP;
  public fileErrors: BehaviorSubject<any> = new BehaviorSubject([]);
  public dataError = new BehaviorSubject<boolean>(false);
  private textOfFile: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private http: HttpClient,
    private _authService: AuthenticationService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  setHeaders(hmacData) {
    let options;
    let user = this.getFromLocal('currentUserTrellis');
    if (!!user) {
      let token = user;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'jwt ' + token,
        Signature: hmacData.HMAC_Sign,
        Key: hmacData.user_key,
        timestamp: hmacData.timeStamp,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      });
      options = {
        headers: headers,
      };
    }
    return options;
  }

  setFileHeader(hmacData) {
    let headers;
    let user = this.getFromLocal('currentUserTrellis');
    if (!!user) {
      let token = user;
      headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'jwt ' + token,
        Signature: hmacData.HMAC_Sign,
        Key: hmacData.user_key,
        timestamp: hmacData.timeStamp,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      });
    }

    return headers;
  }

  setFileHeadersForBatch(hmacData) {
    let headers;
    let user = this.getFromLocal('currentUserTrellis');
    if (!!user) {
      let token = user;
      headers = new HttpHeaders({
        Authorization: 'jwt ' + token,
        Signature: hmacData.HMAC_Sign,
        Key: hmacData.user_key,
        timestamp: hmacData.timeStamp,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      });
    }

    return headers;
  }

  /**GETTERS */
  getKeys() {
    if (
      sessionStorage.getItem('userKey') &&
      sessionStorage.getItem('secretKey')
    ) {
      return {
        userKey: sessionStorage.getItem('userKey'),
        secretKey: sessionStorage.getItem('secretKey'),
      };
    } else {
      return;
    }
  }

  getFromLocal(item) {
    return sessionStorage.getItem(item);
  }

  toggleActive(id, flag): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.toggleAct,
            method: 'POST',
            parameters: { user_id: id, activation: flag },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.toggleAct,
                { user_id: id, activation: flag },
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;

                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length &&
                    subscribedData.error == ''
                  ) {
                    observer.next({
                      status: 'ok',
                      id: subscribedData.data.user_id,
                    });
                    observer.complete();
                  } else {
                    this.errorMessage.next([]);
                    this.errorMessage
                      .getValue()
                      .push(
                        (subscribedData.error_code
                          ? subscribedData.error_code
                          : '') +
                          ': ' +
                          subscribedData.error
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessage.next([]);
                    this.errorMessage
                      .getValue()
                      .push(
                        (err.error && err.error.error_code
                          ? err.error.error_code
                          : '') +
                          ': Something went wrong, Please try again later!'
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
      }
    });
  }

  GetUsers(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getAllUsers,
            method: 'GET',
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .get(
                this.serverURL + APIRoutes.getAllUsers,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length &&
                    response.error == ''
                  ) {
                    observer.next({
                      status: 'ok',
                      users: response.data.user,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessage
                      .getValue()
                      .push(
                        (err.error && err.error.error_code
                          ? err.error.error_code
                          : '') +
                          ': Something went wrong in organizations, Please try again later!'
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
      }
    });
  }

  getQuotaDetails(): Observable<any> {
    return new Observable((observer) => {
      let obj = { user_id: sessionStorage.getItem('userID') };
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getQutaDetails,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.getQutaDetails,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data).length &&
                    response.error == ''
                  ) {
                    observer.next({
                      status: 'ok',
                      details: response.data.quota_history[0],
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessage
                      .getValue()
                      .push(
                        (err.error && err.error.error_code
                          ? err.error.error_code
                          : '') +
                          ': Something went wrong in getting quota details, Please try again later!'
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
      }
    });
  }

  /**ADDERS */
  addUser(formValue): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.createUser,
            method: 'POST',
            parameters: formValue,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.createUser,
                formValue,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;

                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                      id: subscribedData.data.user_id,
                    });
                    observer.complete();
                  } else {
                    this.errorMessage.next([]);
                    this.errorMessage
                      .getValue()
                      .push(
                        (subscribedData.error_code
                          ? subscribedData.error_code
                          : '') +
                          ': ' +
                          subscribedData.error
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessage.next([]);
                    this.errorMessage
                      .getValue()
                      .push(
                        (err.error && err.error.error_code
                          ? err.error.error_code
                          : '') +
                          ': Something went wrong, Please try again later!'
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
      }
    });
  }

  /**UPDATERS */
  updateUser(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.updateQuota,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.updateQuota,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;

                  if (
                    subscribedData &&
                    subscribedData.data &&
                    Object.keys(subscribedData.data).length &&
                    subscribedData.error == ''
                  ) {
                    observer.next({ status: 'ok' });
                    observer.complete();
                  } else {
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    this.errorMessage.next([]);
                    this.errorMessage
                      .getValue()
                      .push(
                        (err.error && err.error.error_code
                          ? err.error.error_code
                          : '') +
                          ': Something went wrong in deleting user, Please try again later!'
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error' });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        observer.next({ status: 'error' });
        observer.complete();
      }
    });
  }

  resetLicense(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.renewLicenseAPI,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(
                this.serverURL + APIRoutes.renewLicenseAPI,
                obj,
                this.setHeaders(headerOptions)
              )
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    subscribedData &&
                    subscribedData.data &&
                    subscribedData.error == '' &&
                    Object.keys(subscribedData.data).length
                  ) {
                    observer.next({
                      status: 'ok',
                      quotaData: subscribedData.data.quota[0],
                    });
                    observer.complete();
                  } else {
                    this.errorMessage.next([]);
                    this.errorMessage
                      .getValue()
                      .push(
                        (subscribedData.error_code
                          ? subscribedData.error_code
                          : '') +
                          ': ' +
                          subscribedData.error
                      );
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({
                      status: 'error',
                      code: subscribedData.error_code,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  this.errorMessage.next([]);
                  this.errorMessage
                    .getValue()
                    .push(
                      (err.error && err.error.error_code
                        ? err.error.error_code + ' :'
                        : '') +
                        'Something went wrong in renew organization license , Please try again later!'
                    );
                  this.errorMessage.next(this.errorMessage.getValue());
                  observer.next({
                    status: 'error',
                    code: err.error.error_code,
                  });
                  observer.complete();
                }
              );
          });
      }
    });
  }

  screeningCase(obj): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.screenCase,
            method: 'POST',
            parameters: obj,
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };
            this.http
              .post(this.serverURL + APIRoutes.screenCase, obj, {
                headers: this.setFileHeader(headerOptions),
                responseType: 'arraybuffer',
              })
              .subscribe(
                (response) => {
                  let subscribedData: any = response;
                  if (
                    typeof subscribedData == 'string' &&
                    subscribedData.includes('error_code')
                  ) {
                    subscribedData = JSON.parse(subscribedData);
                  } else {
                    subscribedData = subscribedData;
                  }
                  if (
                    subscribedData &&
                    subscribedData.error &&
                    subscribedData.error != ''
                  ) {
                    let errMsg = subscribedData.error
                      ? subscribedData.error.split(':').pop()
                      : 'An error has occurred while screening, please try again later!';

                    this.errorMessage.next([]);
                    this.errorMessage.getValue().push(errMsg);
                    this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({
                      status: 'error',
                      code: subscribedData.error_code,
                    });
                    observer.complete();
                  } else {
                    observer.next({
                      status: 'ok',
                      pdfData: subscribedData,
                    });
                    observer.complete();
                  }
                },
                (err) => {
                  let errMsg = JSON.parse(
                    String.fromCharCode.apply(null, new Uint8Array(err.error))
                  );
                  errMsg = errMsg.error
                    ? errMsg.error.split(':').pop()
                    : 'An error has occurred while screening, please try again later!';

                  this.errorMessage.next([]);
                  this.errorMessage.getValue().push(errMsg);
                  this.errorMessage.next(this.errorMessage.getValue());
                  observer.next({
                    status: 'error',
                    code: err.error.error_code,
                  });
                  observer.complete();
                }
              );
          });
      }
    });
  }

  readFileData(file): Observable<any> {
    return new Observable((observer) => {
      if (file) {
        let fileReader = new FileReader();
        fileReader.readAsText(file, 'utf-8');

        fileReader.addEventListener('load', (event: any) => {
          this.textOfFile = fileReader.result;
          if (this.textOfFile.split('\n').length <= 1000002) {
            let obj = {
              data: this.textOfFile,
              name: file.name,
            };
            observer.next({ status: 'ok', fileObj: obj });
            observer.complete();
          } else {
            this.fileErrors
              .getValue()
              .push('File size cannot exceeds more than 5 MB!');
            this.fileErrors.next(this.fileErrors.getValue());
          }
        });
      }
    });
  }

  createJobForBatchSearch(fileData, file, category): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      let hmacData = {
        category,
        upload_file_name: fileData,
      };

      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.createJobAPI,
            method: 'POST',
            parameters: { ...hmacData },
          })
          .subscribe((res) => {
            let headerOptions = {
              HMAC_Sign: res.HMAC_Sign,
              user_key: res.user_key,
              timeStamp: res.timeStamp,
            };

            let formData: FormData = new FormData();
            formData.append('category', category);
            formData.append('upload_file_name', file);

            this.http
              .post(this.serverURL + APIRoutes.createJobAPI, formData, {
                headers: this.setFileHeadersForBatch(headerOptions),
              })
              .subscribe(
                (res) => {
                  let response: any = res;

                  if (
                    response &&
                    response.data &&
                    Object.keys(response.data) &&
                    response.data['alert_case'] != 'failed'
                  ) {
                    observer.next({
                      status: 'ok',
                      usercase: response.data,
                    });
                    observer.complete();
                  } else {
                    observer.next({ status: 'error', error: response.error });
                    observer.complete();
                  }
                },
                (err) => {
                  if (err.error) {
                    // this.errorMessage
                    //   .getValue()
                    //   .push(
                    //     (err.error && err.error.error_code
                    //       ? err.error.error_code
                    //       : '') +
                    //     ': Something went wrong in screening case, Please try again later!'
                    //   );
                    // this.errorMessage.next(this.errorMessage.getValue());
                    observer.next({ status: 'error', error: err.error });
                    observer.complete();
                  }
                }
              );
          });
      } else {
        this.dataError.next(true);
        observer.next({ status: 'error' });
        observer.complete();
      }
    });
  }

  getBatchSearchData(): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();
      if (keys) {
        this._authService
          .HMACGeneration(keys.userKey, keys.secretKey, {
            endPoint: '/' + APIRoutes.getStatusAPI,
            method: 'POST',
          })
          .subscribe(
            (res) => {
              let headerOptions = {
                HMAC_Sign: res.HMAC_Sign,
                user_key: res.user_key,
                timeStamp: res.timeStamp,
              };
              this.http
                .post(
                  this.serverURL + APIRoutes.getStatusAPI,
                  null,
                  this.setHeaders(headerOptions)
                )
                .subscribe(
                  (res) => {
                    let response: any = res;
                    if (response && response.data) {
                      observer.next({
                        status: 'ok',
                        jobs: response.data,
                      });
                      observer.complete();
                    } else {
                      observer.next({ status: 'error' });
                      observer.complete();
                    }
                  },
                  (err) => {
                    if (err.error) {
                      // console.log(err.error);
                      if (
                        err.status == 401 ||
                        err.statusText == 'Unauthorized'
                      ) {
                        localStorage.clear();
                        sessionStorage.clear();
                        this._router.navigate(['/login']);
                        this._snackBar.open(
                          err.error.detail || 'Error in getting batch jobs',
                          'Dismiss',
                          {
                            duration: 5000,
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                          }
                        );
                      }
                      this.errorMessage
                        .getValue()
                        .push(
                          (err.error && err.error.error_code
                            ? err.error.error_code
                            : '') +
                            ': Something went wrong in getting batch search, Refresh to try again!'
                        );
                      this.errorMessage.next(this.errorMessage.getValue());
                      observer.next({ status: 'error' });
                      observer.complete();
                    }
                  }
                );
            },
            (err) => {
              this.errorMessage
                .getValue()
                .push(
                  (err.error && err.error.error_code
                    ? err.error.error_code
                    : '') +
                    ': Something went wrong in creating hmac, Refresh to try again!'
                );
              this.errorMessage.next(this.errorMessage.getValue());
              observer.next({ status: 'error' });
              observer.complete();
            }
          );
      } else {
        this.dataError.next(true);
        observer.next({ status: 'error' });
        observer.complete();
      }
    });
  }
}
